// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // AUTH_API: 'http://127.0.0.1:8600/auth-api',

  AUTH_API: 'https://dev.bushezhouye.com/auth-api',
  KNOWLEDGE_API: 'https://dev.bushezhouye.com/knowledge-api',
  OSS_API: 'https://dev.bushezhouye.com/oss-api',
  PURCHASE_API: 'https://dev.bushezhouye.com/purchase-api',

  // AUTH_API: 'http://192.168.10.80:8600/bushezhouye-auth',
  // KNOWLEDGE_API: 'http://192.168.10.80:8600/bushezhouye-knowledge',

  // AUTH_API: 'http://127.0.0.1:8600/bushezhouye-auth',
  // KNOWLEDGE_API: 'http://127.0.0.1:8600/bushezhouye-knowledge',
  QINIU_UPLOAD_URL: 'https://upload-z2.qiniup.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
