<div>
  <div class="word-2-pdf-content" *ngIf="knowledgeContent?.type === 1">
    <ng-container *ngIf="knowledgeContent?.url; else wordNotReady">
<!--      <object type="application/pdf" [data]="fileUrl"></object>-->
      <iframe [src]="fileUrl"></iframe>
    </ng-container>
    <ng-template #wordNotReady>
      <nz-spin class="pdf-loading-spin" [nzTip]="pdfLoadCounter + ''">
        <nz-alert
          nzType="info"
          nzMessage="请稍等，文件正在准备中。"
          nzDescription="逝者如斯夫，不舍昼夜。"></nz-alert>
      </nz-spin>

    </ng-template>
  </div>

  <div class="pdf-content" *ngIf="knowledgeContent?.type === 2">
<!--    <object type="application/pdf" [data]="fileUrl"></object>-->
    <iframe [src]="fileUrl"></iframe>
  </div>

  <div class="image-content" *ngIf="knowledgeContent?.type === 3">
    <img nz-image [nzSrc]="imageUrl"/>
  </div>

  <div class="text-content" *ngIf="knowledgeContent?.type === 4">
    <markdown
      lineNumbers
      [data]="knowledgeText">
    </markdown>
  </div>
</div>
