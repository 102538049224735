import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginGuardCanLoad} from './guard/login.guard';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'index'
  },
  {
    path: 'index',
    loadChildren: () => import('./index/index.module').then(m => m.IndexModule)
  },
  {
    path: 'console',
    loadChildren: () => import('./console/console.module').then(m => m.ConsoleModule),
    canLoad: [LoginGuardCanLoad]
  },
  {
    path: 'passport', loadChildren: () => import('./passport/passport.module').then(m => m.PassportModule)
  },
  {
    path: 'app-download',
    loadChildren: () => import('./app-download/app-download.module').then(m => m.AppDownloadModule)
  },
  {
    path: 'interaction',
    loadChildren: () => import('./interaction/interaction.module').then(m => m.InteractionModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
