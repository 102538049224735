import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {KnowledgeContentApi} from "../../api/knowledge-content.api";
import {KnowledgeContentService} from "../../service/KnowledgeContentService";
import {HttpResponseEntity} from "../../entity/http-result";
import KnowledgeContent = HttpResponseEntity.KnowledgeContent;
import {Subscription} from "rxjs";

@Component({
  selector: 'app-knowledge-content',
  templateUrl: './knowledge-content.component.html',
  styleUrls: ['./knowledge-content.component.less']
})
export class KnowledgeContentComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  contentId?: number

  knowledgeContent?: KnowledgeContent

  fileUrl?: SafeUrl;
  imageUrl: string = "error";

  knowledgeText?: string;

  pdfLoadCounter = 30;

  private autoLoadWordInterval: any;
  private contentUpdateSubs: Subscription;

  constructor(private knowledgeContentApi: KnowledgeContentApi,
              private domSanitizer: DomSanitizer,
              private http: HttpClient,
              private knowledgeContentService: KnowledgeContentService) {

    this.contentUpdateSubs = this.knowledgeContentService.contentUpdated$.subscribe({
      next: (contentId: number) => {

        if (contentId === this.contentId) {
          this.loadContent(contentId);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.contentUpdateSubs.unsubscribe();
  }


  ngOnInit(): void {
    if (this.contentId && this.contentId > 0) {
      this.loadContent(this.contentId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('contentId changed ', changes);
    if (changes['contentId'].currentValue) {
      this.loadContent(changes['contentId'].currentValue);
    }
  }


  private loadContent(contentId: number): void {
    this.knowledgeContentApi.getKnowledgeContent(contentId).subscribe({
      next: (knowledgeContent) => {
        this.knowledgeContent = knowledgeContent
        if (this.knowledgeContent.url) {
          // 取消掉定时任务
          clearInterval(this.autoLoadWordInterval);

          if (this.knowledgeContent.type === 4) {
            // 如果是文本，则下载文本内容
            this.loadTextFile(this.knowledgeContent.url);
          } else {
            if (this.knowledgeContent.type == 3) {
              this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.knowledgeContent.url);
              this.imageUrl = this.knowledgeContent.url
            } else {
              this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/pdfjs/web/viewer.html?file=${encodeURIComponent(this.knowledgeContent.url)}`
              );
            }

          }

          this.autoLoadWordInterval = null;
        } else {
          // 文件还没准备好，启动定时加载
          this.autoLoadNewWord(contentId);
        }
      }
    });
  }

  private autoLoadNewWord(contentId: number) {
    if (this.autoLoadWordInterval) {
      // 已经启动过定时了，不需要重复启动
    } else {
      this.pdfLoadCounter = 30;
      this.autoLoadWordInterval = setInterval(() => {

        this.pdfLoadCounter--;
        if (this.pdfLoadCounter % 5 === 0) {
          this.loadContent(contentId);
        }
      }, 1000);
    }
  }

  private loadTextFile(url: string): void {
    this.http.get(url, {
      responseType: 'text'
    }).subscribe({
      next: (text) => {
        this.knowledgeText = text
      },
      error: (err: any) => {
        console.log('err is :', err);
      }
    })
  }
}
