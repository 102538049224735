export class UserLoggedIn {
  static readonly type = '[User] LoggedIn '
}

export class UserLoggedOut {
  static readonly type = '[User] LoggedOut '
}

export class UserUpdated {
  static readonly type = '[User] Updated '
}
