import {Injectable} from "@angular/core";
import {Action, State, StateContext} from "@ngxs/store";
import {UserLoggedIn, UserLoggedOut, UserUpdated} from "./user.actions";

export interface UserStateModel {
  loggedIn: boolean;
  updated: boolean;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    loggedIn: false,
    updated: false
  }
})
@Injectable()
export class UserState {

  @Action(UserLoggedIn)
  loggedIn(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loggedIn: true
    });

  }

  @Action(UserLoggedOut)
  loggedOut(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loggedIn: false
    });

  }

  @Action(UserUpdated)
  updated(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updated: true
    });

  }
}
