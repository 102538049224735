<div class="app-header">
  <div class="header-left">
    <div class="app-logo" [routerLink]="['/']">
      <img src="assets/logo_200px.png" alt="logo">
      <span>不舍昼夜</span>
    </div>

    <div class="app-nav">
      <!--
      <ng-container *ngIf="loggedIn">
        <div [routerLink]="['/console','my-knowledge']" routerLinkActive="active">我的知识</div>
        <div [routerLink]="['/console','review']" routerLinkActive="active">复习</div>
      </ng-container>

      <div [routerLink]="['/index', 'official-knowledge-repo']" routerLinkActive="active">知识大厅</div>

      <div [routerLink]="['/app-download']" routerLinkActive="active">APP下载</div>
      <div [routerLink]="['/interaction']" routerLinkActive="active">一起交流</div>
      -->
      <ng-container *ngTemplateOutlet="appNavTpl"></ng-container>
    </div>
  </div>

  <div class="header-right">
    <div class="expand-menu-btn">
      <button nz-button nzType="text" nzSize="large" (click)="toggleSmallScreenProfile()">
        <span nz-icon nzType="user" nzTheme="outline"></span>
      </button>

      <button nz-button nzType="text" nzSize="large" (click)="toggleSmallScreenMenu()">
        <span nz-icon nzType="menu" nzTheme="outline"></span>
      </button>
    </div>

    <div class="app-profile" *ngIf="!loggedIn">
      <button nz-button nzType="text" [routerLink]="['passport','login']">
        登录
      </button>
      <button nz-button nzType="primary" [routerLink]="['passport','sign-up']">
        注册
      </button>
    </div>

    <ng-container *ngIf="loggedIn">
      <div class="user-profile" nz-dropdown [nzDropdownMenu]="profileDropdownMenu">

        <span>{{ userProfile?.nickName }}</span>

        <ng-container *ngIf="avatarUrl">
          <img [src]="avatarUrl">
        </ng-container>
        <ng-container *ngIf="!avatarUrl">
          <img src="assets/default-avatar.svg">
        </ng-container>


        <i class="avatar-arrow" nz-icon nzType="caret-down" nzTheme="outline"></i>
      </div>

      <nz-dropdown-menu #profileDropdownMenu="nzDropdownMenu">
        <div nz-menu nzSelectable>
          <div nz-menu-item [routerLink]="['/console','user-center']">
            <a>
              <i nz-icon nzType="setting"></i>
              个人中心
            </a>
          </div>

          <div nz-menu-item [routerLink]="['/console','user-center','user-vip']">
            <div style="display: flex; flex-direction: column;">
              <div style="display: block;">
                <a>
                  <i nz-icon nzType="crown"></i>
                  <ng-container *ngIf="userProfile?.vipLevelName else noVip">
                    {{ userProfile?.vipLevelName }}
                  </ng-container>
                  <ng-template #noVip>
                    购买会员
                  </ng-template>
                </a>
              </div>

              <div *ngIf="userProfile?.vipLevelId" style="display: block;font-size: 12px;color: #8c8c8c;">
                <ng-container *ngIf="!userProfile?.purchasedVipExpired; else purchasedVipExpiredHeadTpl">
                  <span *ngIf="userProfile?.vipPlanId && userProfile?.vipExpiredTime">
                    有效期至：{{ userProfile?.vipExpiredTime | dateTimeISO }}
                  </span>
                </ng-container>
                <ng-template #purchasedVipExpiredHeadTpl>
                  <span *ngIf="userProfile?.vipExpiredTime && userProfile?.purchasedVipExpired">
                    {{ userProfile?.vipExpiredTime | dateTimeISO }} 已过期
                  </span>
                </ng-template>
              </div>
            </div>

          </div>

          <nz-divider style="margin: 4px 0;"></nz-divider>

          <div nz-menu-item (click)="logout()">
            <a>
              <i nz-icon nzType="logout"></i>
              退出登录
            </a>
          </div>
        </div>
      </nz-dropdown-menu>
    </ng-container>

  </div>

  <div class="app-small-screen-menu" *ngIf="showSmallScreenMenu">
    <div class="close-btn">
      <button nz-button nzType="text" nzSize="large" (click)="showSmallScreenMenu = false">
        <span nz-icon nzType="close" nzTheme="outline"></span>
      </button>
    </div>

    <div class="app-small-screen-nav">
      <ng-container *ngTemplateOutlet="appNavTpl"></ng-container>
    </div>

  </div>

  <div class="app-small-screen-profile" *ngIf="showSmallScreenProfile">
    <div class="close-btn">
      <button nz-button nzType="text" nzSize="large" (click)="showSmallScreenProfile = false">
        <span nz-icon nzType="close" nzTheme="outline"></span>
      </button>
    </div>

    <div class="app-small-screen-profile-content">
      <div class="app-profile" *ngIf="!loggedIn">
        <button nz-button nzType="text" [routerLink]="['passport','login']" (click)="showSmallScreenProfile=false"
                [nzSize]="'large'">
          登录
        </button>
        <button nz-button [nzType]="'text'" [routerLink]="['passport','sign-up']"
                (click)="showSmallScreenProfile=false"
                [nzSize]="'large'">
          注册
        </button>
      </div>

      <ng-container *ngIf="loggedIn">
        <div class="user-profile">
          <ng-container *ngIf="avatarUrl">
            <img [src]="avatarUrl">
          </ng-container>
          <ng-container *ngIf="!avatarUrl">
            <img src="assets/default-avatar.svg">
          </ng-container>
          <span>{{ userProfile?.nickName }}</span>
        </div>

        <div>
          <div nz-menu nzSelectable (click)="showSmallScreenProfile = false">
            <div nz-menu-item [routerLink]="['/console','user-center']">
              <a>
                <i nz-icon nzType="setting"></i>
                个人中心
              </a>
            </div>

            <div nz-menu-item [routerLink]="['/console','user-center','user-vip']">
              <div style="display: flex; flex-direction: column;">
                <div style="display: block;">
                  <a>
                    <i nz-icon nzType="crown"></i>
                    <ng-container *ngIf="userProfile?.vipLevelName else noVip">
                      {{ userProfile?.vipLevelName }}
                    </ng-container>
                    <ng-template #noVip>
                      购买会员
                    </ng-template>
                  </a>
                </div>

                <div *ngIf="userProfile?.vipLevelId" style="display: block;font-size: 12px;color: #8c8c8c;">
                  <ng-container *ngIf="!userProfile?.purchasedVipExpired; else purchasedVipExpiredHeadTpl">
                  <span *ngIf="userProfile?.vipPlanId && userProfile?.vipExpiredTime">
                    有效期至：{{ userProfile?.vipExpiredTime | dateTimeISO }}
                  </span>
                  </ng-container>
                  <ng-template #purchasedVipExpiredHeadTpl>
                  <span *ngIf="userProfile?.vipExpiredTime && userProfile?.purchasedVipExpired">
                    {{ userProfile?.vipExpiredTime | dateTimeISO }} 已过期
                  </span>
                  </ng-template>
                </div>
              </div>

            </div>

            <nz-divider style="margin: 4px 0;"></nz-divider>

            <div nz-menu-item (click)="logout()">
              <a>
                <i nz-icon nzType="logout"></i>
                退出登录
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</div>


<ng-template #appNavTpl>
  <ng-container *ngIf="loggedIn">
    <div (click)="showSmallScreenMenu = false;" [routerLink]="['/console','my-knowledge']" routerLinkActive="active">
      我的知识
    </div>
    <div (click)="showSmallScreenMenu = false;" [routerLink]="['/console','review']" routerLinkActive="active">复习
    </div>
  </ng-container>

  <div (click)="showSmallScreenMenu = false;" [routerLink]="['/index', 'official-knowledge-repo']"
       routerLinkActive="active">知识大厅
  </div>

  <div (click)="showSmallScreenMenu = false;" [routerLink]="['/app-download']" routerLinkActive="active">APP下载</div>
  <div (click)="showSmallScreenMenu = false;" [routerLink]="['/interaction']" routerLinkActive="active">一起交流</div>
  <div (click)="showSmallScreenMenu = false;" [routerLink]="['/contact-us']" routerLinkActive="active">联系我们</div>
</ng-template>
