import {Directive, ElementRef, AfterViewInit, AfterViewChecked, AfterContentInit} from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit{

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // 没有这个延迟还不行
      this.el.nativeElement.focus();
    },500)
  }

}
