import {Component} from '@angular/core';

@Component({
  selector: 'app-beian',
  templateUrl: './beian.component.html',
  styleUrls: ['./beian.component.less']
})
export class BeianComponent {

  year: number;

  constructor() {
    this.year = new Date().getFullYear();
  }
}
