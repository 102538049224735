import {inject} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpRequest
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  aesDecrypt,
  aesEncrypt,
  getJwtClaimsSet,
  isValidSign,
  randomAesIV,
  randomAesKey,
  signSHA256
} from '../utils/security-utils';
import {environment} from '../../environments/environment';
import {HttpResult} from '../entity/http-result';
// @ts-ignore
import * as jsrsasign from 'jsrsasign';
import {PublicKeyApi} from './public-key.api';
import {TERM_ACCESS_TOKEN, TERM_REFRESH_TOKEN} from '../app-constants';
import {Router} from '@angular/router';
import {Store} from "@ngxs/store";
import {AppState, AppStateModel} from "../state/app/app.state";
import {formatISO} from "date-fns";


function getAccessToken(): Observable<string | null> {
  const publicKeyApi = inject(PublicKeyApi)
  const http = inject(HttpClient)

  const accessToken = localStorage.getItem(TERM_ACCESS_TOKEN);
  if (accessToken) {
    // 判断accessToken是否还在有效期内
    const claimsSet = getJwtClaimsSet(accessToken);

    const now = Date.now() / 1000;
    const expireOffsetSec = claimsSet.exp - now;
    if (expireOffsetSec > 300 || claimsSet.TokenType === 'TempToken') {
      // 如果离过期时间还有5分钟以上的时间，则直接使用
      // 如果是临时token，也直接使用
      return of(accessToken);
    } else {
      // 重新从后台获取新的AccessToken
      return publicKeyApi.getPublicKey().pipe(switchMap((publicKey: string) => {
        // 本地生成一串随机字符串，用作aes加密的key
        const aesKey = randomAesKey();
        const aesIv = randomAesIV();

        const cryptoKey = jsrsasign.KEYUTIL.getKey(publicKey);

        const refreshToken = localStorage.getItem(TERM_REFRESH_TOKEN)!;
        // 用aes加密refreshToken，因为refreshToken太长了，用rsa加密的话会报错。
        const encryptedRefreshToken = aesEncrypt(refreshToken, aesKey, aesIv);

        // 用rsa加密aes的key和iv
        const encryptedAesKey = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, cryptoKey);
        const encryptedIv = jsrsasign.KJUR.crypto.Cipher.encrypt(aesIv, cryptoKey);

        const timestamp = formatISO(new Date());
        const timestampHex = aesEncrypt(timestamp, aesKey, aesIv);
        const signRaw = `${refreshToken}${aesKey}${aesIv}${timestamp}`;
        const signResult = signSHA256(signRaw)
        const signHex = aesEncrypt(signResult, aesKey, aesIv);

        const hexBody = `${encryptedRefreshToken}o${encryptedAesKey}o${encryptedIv}o${timestampHex}o${signHex}`;

        return http.post<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/refresh-token`, hexBody)
          .pipe(map((result: HttpResult<string>) => {
            if (HttpResult.succeed(result.code)) {

              const loginData = result.data!.split('o');
              const hexAccessToken = loginData[0];
              const hexRefreshToken = loginData[1];
              const sign = loginData[2];

              const validSign = isValidSign(hexAccessToken + 'o' + hexRefreshToken, sign, cryptoKey);
              if (validSign) {

                const newAccessToken = aesDecrypt(hexAccessToken, aesKey, aesIv);
                const newRefreshToken = aesDecrypt(hexRefreshToken, aesKey, aesIv);

                localStorage.setItem(TERM_ACCESS_TOKEN, newAccessToken);
                localStorage.setItem(TERM_REFRESH_TOKEN, newRefreshToken);

                return newAccessToken;
              } else {
                // 签名错误
                throw new HttpErrorResponse({
                  status: 401
                });
              }
            } else {
              // refresh token 失败
              throw new HttpErrorResponse({
                status: 401
              });
            }
          }));

      }));

    }
  } else {
    return of(null);
  }
}

const ignoredUrls = [
  {
    method: 'GET',
    url: `${environment.BUSHEZHOUYE_API}/v3/security/public-key`
  },
  {
    method: 'POST',
    url: `${environment.BUSHEZHOUYE_API}/v2/admin-login/account`
  },
  {
    method: 'POST',
    url: `${environment.BUSHEZHOUYE_API}/v3/refresh-token`
  },
  {
    method: 'GET',
    url: `${environment.BUSHEZHOUYE_API}/v2/official-knowledge-repo`
  },
  {
    method: 'GET',
    url: `${environment.BUSHEZHOUYE_API}/v2/official-knowledge-course`
  },
  {
    method: 'POST',
    url: `${environment.BUSHEZHOUYE_API}/v2/contact-us`
  }
];

export function apiInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const router = inject(Router)
  const store = inject(Store)

  // 给每个请求增加terminal的请求头
  let headers = new HttpHeaders().append('Terminal', 'Web');
  if (req.headers.get("Access-Principal-Role") === 'ANONYMOUS') {
    // 标记为匿名的，就不需要添加token了
    return next(req.clone({
      headers
    }));
  }

  for (const ignoredUrl of ignoredUrls) {
    if (req.url === ignoredUrl.url && req.method === ignoredUrl.method) {
      return next(req.clone({
        headers
      }));
    }
  }

  return getAccessToken().pipe(switchMap((accessToken: string | null) => {

    if (accessToken) {
      headers = headers.append('Authorization', `Bearer ${accessToken}`);
    }

    return next(req.clone({
      headers
    }));

  }), catchError((err: any) => {
    if (err instanceof HttpErrorResponse) {
      // TODO: 这里还要改造下，把下面的status401RedirectLogin用起来
      if (err.status === 401 /*&& this.status401RedirectLogin*/) {
        const appState = store.selectSignal<AppStateModel>(AppState.getState);
        const status401RedirectLogin = appState().status401RedirectLogin;
        console.log("APP状态是:", status401RedirectLogin);

        if (status401RedirectLogin) {
          router.navigate(['/', 'passport', 'login']);
        }

        // router.navigate(['/', 'passport', 'login']);

      }

    }
    return throwError(err);
  }));

}
