
export class HttpResult<T> {
  code: number;
  message?: string;
  data?: T;

  constructor(code: number, message?: string, data?: T) {
    this.code = code;
    this.message = message;
    this.data = data;
  }

  static succeed(code: number): boolean {
    return code === 0;
  }
}

export interface ApiPage<T> {
  pageNum: number;
  pageSize: number;
  size: number;
  total: number;
  pages: number;
  prePage: number;
  nextPage: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  list: Array<T>;
}

export class ApiSortBy {
  name: string;
  order: 'desc' | 'asc';

  constructor(name: string, order: "desc" | "asc") {
    this.name = name;
    this.order = order;
  }
}

export interface ApiPageable {
  sort: ApiSort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface ApiSort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}


export class PublicKey {
  num: string;
  key: string;

  constructor(num: string, key: string) {
    this.num = num;
    this.key = key;
  }
}

export class UserToken {
  accessToken: string;
  refreshToken: string;

  constructor(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }
}

export interface KnowledgePackRequestBody {
  id?: number;
  name?: string;
  collectionId?: number;
}


export interface KnowledgePackCollection {
  id: number;
  userId: number;
  name: string;
  itemCount: number;
}

export namespace HttpResponseEntity {


  export interface KnowledgeSection {
    id: number;
    packId: number;
    name: string;
    level: number;
    idx: number;
    prev?: number;
    next?: number;
    parentId?: number;
    parentIdxName?: string;
  }


  export interface ReviewHistory {
    time: any;
    userId: number;
    knowledgeId: number;
    knowledgeName: string;
    planTime: any;
    reviewInterval: number;
    offsetMinutes: number;
    punctual: boolean;
  }

  export interface KnowledgeContent {
    id: number;
    type: number;
    url?: string;
  }
}


export namespace HttpRequestBody {
  export namespace KnowledgeSection {
    export interface RequestBodyAdd {
      packId: number;
      name: string;
      parentId?: number;
      prev?: number;
    }

    export interface RequestBodyUpdate {
      id: number;
      name: string;
      parentId?: number;
      prev?: number;
    }
  }

  export namespace Qiniu {
    export interface Token {
      token: string;
      key: string;
    }
  }

  export namespace Knowledge {
    export interface RequestBodyAdd {
      packId?: number;
      sectionId?: number;
      title: string;
      ossId?: number;
      textContent?: string;
    }

    export interface RequestBodyUpdate {
      id: number;
      title: string;
      docx: number;
    }

  }

}


