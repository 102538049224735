import {Injectable} from "@angular/core";
import {BaseApi} from "./base.api";
import {HttpClient} from "@angular/common/http";
import {HttpResponseEntity, HttpResult} from "../entity/http-result";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import KnowledgeContent = HttpResponseEntity.KnowledgeContent;

@Injectable({
  providedIn: 'root'
})
export class KnowledgeContentApi extends BaseApi {
  constructor(private http: HttpClient) {
    super();
  }

  getKnowledgeContent(contentId: number): Observable<KnowledgeContent> {
    return this.httpResultMap(this.http.get<HttpResult<KnowledgeContent | undefined>>(`${environment.KNOWLEDGE_API}/v1/knowledge-content/${contentId}`))
  }
}
