import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class KnowledgeContentService {

  private contentUpdatedSource = new Subject<number>();

  contentUpdated$ = this.contentUpdatedSource.asObservable();

  contentUpdate(contentId: number): void {
    this.contentUpdatedSource.next(contentId);
  }
}
