import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NZ_I18N} from 'ng-zorro-antd/i18n';
import {zh_CN} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IconsProviderModule} from './icons-provider.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {ApiInterceptor} from './api/api-interceptor';
import {LoginGuardCanLoad} from './guard/login.guard';
import {NgxsModule} from "@ngxs/store";
import {UserState} from "./state/user/user.state";
import {environment} from "../environments/environment";
import {AppCommonModule} from "./app-common.module";
import {AppState} from "./state/app/app.state";

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    AppCommonModule,
    NzLayoutModule,
    NzMenuModule,
    NgxsModule.forRoot([UserState, AppState], {
      developmentMode: !environment.production
    })
  ],
  providers: [
    {
      provide: NZ_I18N, useValue: zh_CN
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true
    },
    LoginGuardCanLoad
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
