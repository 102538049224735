import {Component} from '@angular/core';
import {NzIconService} from 'ng-zorro-antd/icon';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AppHeaderComponent} from './components/app-header/app-header.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, AppHeaderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = '不舍昼夜';

  constructor(private nzIcon: NzIconService) {
    this.nzIcon.fetchFromIconfont({
      scriptUrl: './assets/iconfont.js',
    });
  }
}
