import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {HttpResult, PublicKey} from '../entity/http-result';
import {Observable, switchMap} from 'rxjs';
import {PublicKeyApi} from './public-key.api';
// @ts-ignore
import * as jsrsasign from 'jsrsasign';
import {BaseApi} from "./base.api";
import {UserProfile} from "../entity/http-vo";

@Injectable({
  providedIn: 'root'
})
export class UserProfileApi extends BaseApi {
  constructor(private http: HttpClient, private publicKeyApi: PublicKeyApi) {
    super()
  }

  getProfile(): Observable<UserProfile> {
    return this.httpResultMap(this.http.get<HttpResult<UserProfile>>(`${environment.AUTH_API}/v1/user-profile`))
  }

  updateProfile(formData: FormData): Observable<UserProfile> {
    return this.httpResultMap(this.http.post<HttpResult<UserProfile>>(`${environment.AUTH_API}/v1/user-profile`, formData))
  }


}
