import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpResult, PublicKey} from '../entity/http-result';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {wrapPublicKey} from '../utils/security-utils';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PublicKeyApi {
  constructor(private http: HttpClient) {
  }

  getPublicKey(): Observable<PublicKey> {
    // 先获取一个公钥
    return this.http.get<HttpResult<PublicKey>>(`${environment.AUTH_API}/v1/security/public-key`).pipe(map((result: HttpResult<PublicKey>) => {
      if (HttpResult.succeed(result.code)) {
        return new PublicKey(result.data!.num, wrapPublicKey(result.data!.key));
      } else {
        throw new Error(result.message);
      }
    }));
  }
}
