import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {aesDecrypt, aesEncrypt, getJwtClaimsSet, isValidSign, randomAesIV, randomAesKey} from '../utils/security-utils';
import {environment} from '../../environments/environment';
import {HttpResult, PublicKey} from '../entity/http-result';
// @ts-ignore
import * as jsrsasign from 'jsrsasign';
import {PublicKeyApi} from './public-key.api';
import {TERM_ACCESS_TOKEN, TERM_REFRESH_TOKEN} from '../app-constants';
import {Router} from '@angular/router';
import {Select} from "@ngxs/store";
import {AppState, AppStateModel} from "../state/app/app.state";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  ignoredUrls = [
    {
      method: 'GET',
      url: `${environment.AUTH_API}/v1/security/public-key`
    },
    {
      method: 'POST',
      url: `${environment.AUTH_API}/v1/admin-login/account`
    },
    {
      method: 'POST',
      url: `${environment.AUTH_API}/v1/refresh-token`
    },
    {
      method: 'GET',
      url: `${environment.KNOWLEDGE_API}/v1/official-knowledge-repo`
    },
    {
      method: 'GET',
      url: `${environment.KNOWLEDGE_API}/v1/official-knowledge-course`
    },
    {
      method: 'POST',
      url: `${environment.KNOWLEDGE_API}/v1/contact-us`
    }
  ];

  @Select(AppState) appState?: Observable<AppStateModel>;

  private status401RedirectLogin = false;

  constructor(private http: HttpClient, private publicKeyApi: PublicKeyApi, private router: Router) {
    this.appState?.subscribe({
      next: (state) => {
        // console.log('state is :', state);
        this.status401RedirectLogin = state.status401RedirectLogin;
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 给每个请求增加terminal的请求头
    let headers = new HttpHeaders().append('Terminal', 'Web');
    if (req.headers.get("Access-Principal-Role") === 'ANONYMOUS') {
      // 标记为匿名的，就不需要添加token了
      return next.handle(req.clone({
        headers
      }));
    }

    for (const ignoredUrl of this.ignoredUrls) {
      if (req.url === ignoredUrl.url && req.method === ignoredUrl.method) {
        return next.handle(req.clone({
          headers
        }));
      }
    }

    return this.getAccessToken().pipe(switchMap((accessToken: string | null) => {

      if (accessToken) {
        headers = headers.append('Authorization', `Bearer ${accessToken}`);
      }

      return next.handle(req.clone({
        headers
      }));

    }), catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && this.status401RedirectLogin) {
          this.router.navigate(['/', 'passport', 'login']);
        }
      }
      return throwError(err);
    }));

    // const accessToken = localStorage.getItem('accessToken');
    //
    //
    // // 给每个请求增加terminal的请求头
    // let headers = new HttpHeaders().append('Terminal', 'Web');
    //
    // if (accessToken) {
    //   headers = headers.append('Authorization', `Bearer ${accessToken}`);
    // }
    //
    // const cloneReq = req.clone({
    //   headers
    // });
    //
    // const observable = next.handle(cloneReq);
    //
    // return observable.pipe(
    //   tap((evt: HttpEvent<any>) => {
    //     if (evt.type === HttpEventType.Response) {
    //       const respHeaders = evt.headers;
    //       if (respHeaders) {
    //         const newToken = respHeaders.get('New-Token');
    //         if (newToken) {
    //           localStorage.setItem('accessToken', newToken);
    //         }
    //       }
    //     }
    //   })
    // );


  }


  getAccessToken(): Observable<string | null> {
    const accessToken = localStorage.getItem(TERM_ACCESS_TOKEN);
    if (accessToken) {
      // 判断accessToken是否还在有效期内
      const claimsSet = getJwtClaimsSet(accessToken);

      const now = Date.now() / 1000;
      const expireOffsetSec = claimsSet.exp - now;
      if (expireOffsetSec > 300 || claimsSet.TokenType === 'TempToken') {
        // 如果离过期时间还有5分钟以上的时间，则直接使用
        // 如果是临时token，也直接使用
        return of(accessToken);
      } else {
        // 重新从后台获取新的AccessToken
        return this.publicKeyApi.getPublicKey().pipe(switchMap((publicKey: PublicKey) => {
          // 本地生成一串随机字符串，用作aes加密的key
          const aesKey = randomAesKey();
          const iv = randomAesIV();

          const cryptoKey = jsrsasign.KEYUTIL.getKey(publicKey.key);

          const refreshToken = localStorage.getItem(TERM_REFRESH_TOKEN)!;
          // 用aes加密refreshToken，因为refreshToken太长了，用rsa加密的话会报错。
          const encryptedRefreshToken = aesEncrypt(refreshToken, aesKey, iv);

          // 用rsa加密aes的key和iv
          const encryptedAesKey = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, cryptoKey);
          const encryptedIv = jsrsasign.KJUR.crypto.Cipher.encrypt(iv, cryptoKey);

          const hexBody = `${encryptedRefreshToken}o${encryptedAesKey}o${encryptedIv}`;

          return this.http.post<HttpResult<string>>(`${environment.AUTH_API}/v1/refresh-token`, hexBody, {
            params: {
              keyNum: publicKey.num
            }
          }).pipe(map((result: HttpResult<string>) => {
            if (HttpResult.succeed(result.code)) {

              const loginData = result.data!.split('o');
              const hexAccessToken = loginData[0];
              const hexRefreshToken = loginData[1];
              const sign = loginData[2];

              const validSign = isValidSign(hexAccessToken + 'o' + hexRefreshToken, sign, cryptoKey);
              if (validSign) {

                const newAccessToken = aesDecrypt(hexAccessToken, aesKey, iv);
                const newRefreshToken = aesDecrypt(hexRefreshToken, aesKey, iv);

                localStorage.setItem(TERM_ACCESS_TOKEN, newAccessToken);
                localStorage.setItem(TERM_REFRESH_TOKEN, newRefreshToken);

                return newAccessToken;
              } else {
                // 签名错误
                throw new HttpErrorResponse({
                  status: 401
                });
              }
            } else {
              // refresh token 失败
              throw new HttpErrorResponse({
                status: 401
              });
            }
          }));

        }));

      }
    } else {
      return of(null);
    }
  }


}
