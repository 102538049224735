import {Routes} from '@angular/router';
import {LoginGuardCanMatch} from './guard/login.guard';
import {APP_DOWNLOAD_MODULE_ROUTES} from './app-download/app-download.routes';
import {INTERACTION_MODULE_ROUTES} from './interaction/interaction.routes';

export const APP_ROUTES: Routes = [

  {
    path: '', pathMatch: 'full', redirectTo: 'index'
  },
  {
    path: 'index',
    loadChildren: () => import('./index/index.routes').then(m => m.INDEX_MODULE_ROUTES)
  },
  {
    path: 'passport',
    loadChildren: () => import('./passport/passport.routes').then(m => m.PASSWORD_MODULE_ROUTES)
  },

  {
    path: 'console',
    loadChildren: () => import('./console/console.routes').then(m => m.CONSOLE_MODULE_ROUTES),
    // canLoad: [LoginGuardCanLoad]
    canMatch: [LoginGuardCanMatch]
  }
  ,
  {
    path: 'app-download',
    loadChildren: () => import('./app-download/app-download.routes').then(m => m.APP_DOWNLOAD_MODULE_ROUTES)
  },
  {
    path: 'interaction',
    loadChildren: () => import('./interaction/interaction.routes').then(m => m.INTERACTION_MODULE_ROUTES)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.routes').then(m => m.CONTACT_US_ROUTES)
  }
];
