import {Component} from '@angular/core';
import {NzIconService} from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  constructor(private nzIcon: NzIconService) {
    this.nzIcon.fetchFromIconfont({
      scriptUrl: './assets/iconfont.js',
    });
  }
}
