import {HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpResult} from '../entity/http-result';
import {catchError, map} from 'rxjs/operators';

export abstract class BaseApi {

  public httpResultMap<T>(ob: Observable<HttpResult<T | undefined>>, defaultVal?: T): Observable<T> {

    return ob.pipe(map((result: HttpResult<T | undefined>) => {
      if (HttpResult.succeed(result.code)) {
        return result.data!;
      } else {
        if (defaultVal) {
          return defaultVal;
        } else {
          throw new Error(result.message);
        }
      }
    }), catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        const httpErr = err as HttpErrorResponse;
        if (httpErr.status == 401) {
          throw err;
        } else {
          throw new Error(err.error.message);
        }
      } else {
        throw err;
      }
    }));
  }

  public setAnonymousHeader(headers?: HttpHeaders) {
    if (headers) {
      return headers.append("Access-Principal-Role", "ANONYMOUS")
    }

    return new HttpHeaders({
      "Access-Principal-Role": "ANONYMOUS"
    })
  }

}
