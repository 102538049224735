import {
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
} from '@angular/router';
import {inject} from '@angular/core';
import {TERM_ACCESS_TOKEN} from '../app-constants';

export const LoginGuardCanMatch: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router)

  const accessToken = localStorage.getItem(TERM_ACCESS_TOKEN);
  if (accessToken) {
    // TODO 判断accessToken是否过期了
    return true;
  }
  return router.parseUrl('/passport/login');
};

