import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {TERM_ACCESS_TOKEN} from '../app-constants';

@Injectable()
export class LoginGuardCanActive implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessToken = localStorage.getItem(TERM_ACCESS_TOKEN);
    if (accessToken) {
      return true;
    }
    return this.router.parseUrl('/passport/login');
  }
}


@Injectable()
export class LoginGuardCanLoad implements CanLoad {
  constructor(private router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessToken = localStorage.getItem(TERM_ACCESS_TOKEN);
    if (accessToken) {
      // TODO 判断accessToken是否过期了
      return true;
    }
    return this.router.parseUrl('/passport/login');
  }
}
