import {Component, OnInit} from "@angular/core";
import {Store} from "@ngxs/store";
import {UserLoggedOut, UserState, UserStateModel} from "../../state/user/user.state";
import {Observable} from "rxjs";
import {UserProfileApi} from "../../api/user-profile.api";
import {Router, RouterModule} from "@angular/router";
import {UserProfile} from "../../entity/http-vo";
import {TERM_ACCESS_TOKEN, TERM_REFRESH_TOKEN} from "../../app-constants";
import {environment} from "../../../environments/environment";
import {PassportApi} from "../../api/passport.api";
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {DateTimeISOPipe} from '../../app.pipes';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.less'],
  standalone: true,
  imports: [
    CommonModule, RouterModule, NzDividerModule, DateTimeISOPipe, NzButtonModule, NzDropDownModule, NgOptimizedImage
  ]
})
export class AppHeaderComponent implements OnInit {

  userState: Observable<UserStateModel>;

  loggedIn = false;
  userProfile?: UserProfile;

  avatarUrl?: string;

  showSmallScreenMenu = false
  showSmallScreenProfile = false

  constructor(private userProfileApi: UserProfileApi,
              private passportApi: PassportApi,
              private router: Router,
              private store: Store) {
    this.userState = this.store.select<UserStateModel>(UserState.getState)
  }

  ngOnInit(): void {
    this.userState.subscribe({
      next: (state) => {
        this.loggedIn = state.loggedIn;

        if (this.loggedIn || state.updated) {
          this.loadUserProfile();
        }
      }
    });


    this.loadUserProfile();

  }

  private loadUserProfile(): void {
    this.userProfileApi.getProfile().subscribe({
      next: (userProfile) => {
        this.loggedIn = true;
        this.userProfile = userProfile;
        if (userProfile.avatar) {
          this.avatarUrl = `${environment.BUSHEZHOUYE_API}/v2/public-res?resName=${userProfile.avatar}`;
        }
      },
      error: () => {

      }
    });
  }

  logout(): void {
    this.passportApi.logout().subscribe({
      next: () => {
        this.logoutComplete();
      },
      error: () => {
        this.logoutComplete();
      }
    });

  }

  private logoutComplete() {

    localStorage.removeItem(TERM_ACCESS_TOKEN);
    localStorage.removeItem(TERM_REFRESH_TOKEN);

    this.router.navigate(['/passport/login']);
    this.store.dispatch(new UserLoggedOut()).subscribe({
      next: () => {

      },
      error: () => {

      }
    });
  }

  toggleSmallScreenMenu(): void {
    this.showSmallScreenMenu = !this.showSmallScreenMenu;
  }

  toggleSmallScreenProfile(): void {
    this.showSmallScreenProfile = !this.showSmallScreenProfile;
  }
}
