import {Action, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {SetStatus401RedirectLogin} from "./app.actions";

export interface AppStateModel {
  status401RedirectLogin: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    status401RedirectLogin: false
  }
})
@Injectable()
export class AppState {

  @Action(SetStatus401RedirectLogin)
  loggedIn(ctx: StateContext<AppStateModel>, action: SetStatus401RedirectLogin) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      status401RedirectLogin: action.status
    });
  }
}
