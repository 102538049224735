export const TERM_ACCESS_TOKEN = 'accessToken';
export const TERM_REFRESH_TOKEN = 'refreshToken';

export const TERM_CHANGE_PHONE_NUM_TOKEN = 'changePhoneNumToken';


// 知识标题最大字符数
export const KNOWLEDGE_TITLE_MAX_LENGTH = 500;

// 知识内容最大字符数量
export const KNOWLEDGE_TEXT_CONTENT_MAX_LENGTH = 5000000;

// docx文件上传文件最大字节，10M
export const DOCX_FILE_MAX_SIZE_10M = 10 * 1024 * 1024;

export namespace KnowledgeTypeFilter {
  export const KNOWLEDGE_CONTENT_TYPES = [
    {
      knowledgeType: 'docx',
      fileTypes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    },
    {
      knowledgeType: 'pdf',
      fileTypes: ['application/pdf']
    },
    {
      knowledgeType: 'image',
      fileTypes: ['image/jpeg', 'image/png']
    },
    {
      knowledgeType: 'text',
      fileTypes: ['text/plain', 'text/markdown']
    }
  ]

  export function isValidFile(fileType: string | undefined | null): boolean {
    if (fileType === undefined || fileType === null) {
      return false;
    }
    for (let kct of KNOWLEDGE_CONTENT_TYPES) {
      if (kct.fileTypes.includes(fileType)) {
        return true
      }
    }

    return false;
  }

  export function getKnowledgeContent(fileType: string | null | undefined): string | null {
    if (fileType === undefined || fileType === null) {
      return null;
    }
    for (let kct of KNOWLEDGE_CONTENT_TYPES) {
      if (kct.fileTypes.includes(fileType)) {
        return kct.knowledgeType
      }
    }

    return null;
  }
}
