import {NgModule} from '@angular/core';
import {
  AvatarUrlPipe, ContentTypeIconPipe,
  DateTimeISOPipe, FenToYuanPipe,
  HumanTimeFormatPipe,
  KnowledgeSectionTreePipe,
  LevelIndentPipe,
  PackTypeEnumPipe, PublicResUrlPipe, ReviewOffsetTimeFormatPipe,
  SectionPrefixNamePipe, UnitVolumePipe,
  UpdatedTimeFormatPipe, VipLevelNamePipe, YYYYMMddHHmmssTimeFormatPipe
} from './app.pipes';
import {CommonModule} from '@angular/common';
import {IconsProviderModule} from './icons-provider.module';
import {BeianComponent} from './beian/beian.component';
import {KnowledgeContentComponent} from "./components/knowledge-content/knowledge-content.component";
import {MarkdownModule} from "ngx-markdown";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {RouterModule} from "@angular/router";
import {AppHeaderComponent} from "./components/app-header/app-header.component";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {AutofocusDirective} from "./components/auto-focus.directive";
import {NzImageModule} from "ng-zorro-antd/image";

@NgModule({
  imports: [
    CommonModule, IconsProviderModule, HttpClientModule, NzDividerModule, NzSpinModule, NzAlertModule, NzImageModule,
    // markdown的插件
    MarkdownModule.forRoot(
      {loader: HttpClient}
    ), NzButtonModule, NzDropDownModule, NzMenuModule,
    RouterModule.forChild([])
  ],
  declarations: [
    AppHeaderComponent, BeianComponent, KnowledgeContentComponent,
    AutofocusDirective,
    UpdatedTimeFormatPipe, PackTypeEnumPipe, KnowledgeSectionTreePipe, LevelIndentPipe, SectionPrefixNamePipe,
    ReviewOffsetTimeFormatPipe, HumanTimeFormatPipe, PublicResUrlPipe, DateTimeISOPipe, VipLevelNamePipe, AvatarUrlPipe,
    UnitVolumePipe, FenToYuanPipe, YYYYMMddHHmmssTimeFormatPipe, ContentTypeIconPipe
  ],
  exports: [
    AppHeaderComponent, BeianComponent, KnowledgeContentComponent,
    AutofocusDirective,
    CommonModule, IconsProviderModule, HttpClientModule, MarkdownModule,
    UpdatedTimeFormatPipe, PackTypeEnumPipe, KnowledgeSectionTreePipe, LevelIndentPipe, SectionPrefixNamePipe,
    ReviewOffsetTimeFormatPipe, HumanTimeFormatPipe, PublicResUrlPipe, DateTimeISOPipe, VipLevelNamePipe, AvatarUrlPipe,
    UnitVolumePipe, FenToYuanPipe, YYYYMMddHHmmssTimeFormatPipe, ContentTypeIconPipe
  ],
  providers: [UpdatedTimeFormatPipe]
})
export class AppCommonModule {

}
